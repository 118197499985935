
import ottomanGrill from "./images/OttomanGrill.png";
// import foodApp from "./images/foodApp.PNG";

const projects = [

  {
    id: 1,
    title: "Ottoman Grill",
    background: ottomanGrill,
    tech: "HTML Sass JavaScript",
    github: "https://github.com/philippe76/Ottoman-Grill",
    url: "https://philippe76.github.io/Ottoman-Grill/"
  }
  // {
  //   id: 2,
  //   title: "FOOD MOBILE APP",
  //   background: foodApp,
  //   tech: "javascript react-native",
  //   github: "https://github.com/philippe76/TimeToLunch",
  //   url: "https://philippe76.github.io/TimeToLunch/",
  //   credits: "Filip ",
  // }

];

export default projects;
